<template>
    <modal :open="modalOpen" @close="$emit('closeModal')" :loading="modalLoading" :modalData="modalData">
        <template v-slot:modalContent>
            <div class="text-h6 font-weight-bold text-capitalize pb-5">
                Unsynced Transactions
            </div>
            <base-card elevation="3">
                <v-data-table :headers="headers" :items="item"/>
            </base-card>
        </template>
    </modal>
</template>

<script>
export default {
    props:['modalOpen', 'item'],
    emits: ['closeModal'],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data: (vm) => ({
        modalLoading: false,
        modalData: {
            title: 'View Log',
            width: 1200,
            height: 'auto'
        },
        headers: [
            { text: 'Type',  value: "type"},
            { text: "ID", value: "id" },
            { text: "Description", value: "description" },
            { text: "Amount", value: "amount" },
            { text: "Fee", value: "fee" },
            { text: "Net", value: "net" }
        ],
    })
}
</script>